import AvailableClassCard from "@src/components/AvailableClassCard/AvailableClassCard";
import "./AvailableClasses.scss";
import { ReactComponent as RightArrow } from "@assets/icons/next-arrow-right.svg";
import { ReactComponent as RightArrowFilled } from "@assets/icons/next-arrow-right-filled.svg";
import { ReactComponent as LeftArrow } from "@assets/icons/next-arrow-left.svg";
import { ReactComponent as LeftArrowFilled } from "@assets/icons/next-arrow-left-filled.svg";
import { useEffect, useRef, useState } from "react";
import { useClassesContext } from "@src/context/ClassesContext";
import SpinnerCustom from "@src/components/SpinnerCustom/SpinerCustom";
import ScheduleClassModal from "@src/components/ScheduleClassModal/ScheduleClassModal";
import ScheduleWaitingListModal from "@src/components/ScheduleWaitingListModal/ScheduleWaitingListModal";
import LeaveWaitingListModal from "@src/components/LeaveWaitingListModal/LeaveWaitingListModal";
import DatePicker from "react-date-picker";
import { es } from "date-fns/locale";
import CancelClassModal from "@src/components/CancelClassModal/CancelClassModal";

function AvailableClasses() {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const {
		clases,
		locations,
		currentLocation,
		isFetchingClasses,
		currentDate,
		getClasses,
		nextDay,
		prevDay,
		handleNewLocation,
	} = useClassesContext();
	const locationNavRef: any = useRef(null);

	const [scheduleClassModal, setScheduleClassModal] = useState({
		show: false,
		clase: null,
	});
	const [scheduleWaitingListModal, setScheduleWaitingListModal] = useState({
		show: false,
		clase: null,
	});
	const [leaveWaitingListModal, setLeaveWaitingListModal] = useState({
		show: false,
		clase: null,
	});

	// const isDateInNextClasses = (date: any) => {
	// 	return nextClasses.some(({ id }: any) => new Date(id).getTime() === date.getTime());
	// };

	// const handleFilter = (e: any) => {
	// 	return e.location === locationMap[location];
	// };

	// const handleDateChange = (date: any) => {
	// 	const index = nextClasses.findIndex(({ id }: any) => new Date(id).getTime() === date.getTime());

	// 	if (index >= 0) {
	// 		setActiveIndex(index);
	// 	} else {
	// 		// date is not in array, handle as needed
	// 	}
	// };

	useEffect(() => {
		function handleClickOutside(event: any) {
			if (locationNavRef.current && locationNavRef.current.contains(event.target)) {
				// User clicked outside of the menu
				setIsOpen(true);
			} else {
				setIsOpen(false);
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [locationNavRef]);

	useEffect(() => {
		setIsOpen(false);
	}, [currentLocation]);

	if (!currentLocation) return <></>;

	return (
		<div className="available-classes-main-container">
			<ul className={`location-filter-container ${isOpen ? " isOpen" : ""}`} ref={locationNavRef}>
				{locations.map((loc, index) => {
					return (
						<li
							role="button"
							className={loc.id === currentLocation.id ? "active" : ""}
							onClick={() => handleNewLocation(loc)}
							key={loc.id}
						>
							{loc.name}
						</li>
					);
				})}
			</ul>
			<LeaveWaitingListModal
				show={leaveWaitingListModal.show}
				onHide={() => setLeaveWaitingListModal({ show: false, clase: null })}
				clase={leaveWaitingListModal.clase}
				getClasses={getClasses}
			/>
			<ScheduleClassModal
				show={scheduleClassModal.show}
				onHide={() => setScheduleClassModal({ show: false, clase: null })}
				clase={scheduleClassModal.clase}
			/>
			<ScheduleWaitingListModal
				show={scheduleWaitingListModal.show}
				onHide={() => setScheduleWaitingListModal({ show: false, clase: null })}
				clase={scheduleWaitingListModal.clase}
			/>

			{isFetchingClasses ? (
				<SpinnerCustom />
			) : (
				<div className="calendary-container">
					<div className="calendar-nav">
						{currentDate === null ? (
							<LeftArrow role="button" />
						) : (
							<LeftArrowFilled role="button" onClick={prevDay} />
						)}
						<DatePicker
							className={"custom-dp"}
							value={currentDate}
							clearIcon={null}
							locale={"es-ES"}
							maxDetail={"month"}
							format="d/M/yyyy"
							tileDisabled={({ date, view }) => view === "month"}
						/>
						{currentDate ? <RightArrowFilled role="button" onClick={nextDay} /> : <RightArrow />}
					</div>
					<div className="classes-container">
						{clases.map((classAvailable: any) => {
							return (
								<AvailableClassCard
									class={classAvailable}
									setScheduleClassModal={setScheduleClassModal}
									setScheduleWaitingListModal={setScheduleWaitingListModal}
									setLeaveWaitingListModal={setLeaveWaitingListModal}
									key={classAvailable.id}
								/>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
}

export default AvailableClasses;
