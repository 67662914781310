import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { UserContext, useUserContext } from "./UserContext";
import { Outlet } from "react-router-dom";
import { addClass, getNextClasses } from "@src/services/ClassesService";
import { IUser } from "@src/Interfaces/User";
import { getAge } from "@src/utils/Dates";

interface ClassesContextProviderPropsI {}

export const ClassesContext = createContext<any>({
	nextClasses: [],
	loading: true,
	getClasses: () => {},
});

export function useClassesContext() {
	return useContext(ClassesContext);
}

export const ClassesContextProvider = (props: ClassesContextProviderPropsI) => {
	const [nextClasses, setNextClasses] = useState<Array<any>>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const { user } = useUserContext();

	const getClasses = async () => {
		setLoading(true);
		getNextClasses(user.id)
			.then((res) => {
				setNextClasses(res);
			})
			.catch((error) => {
				throw error;
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getClasses();
	}, []);

	const values = { nextClasses, loading, getClasses };

	if (
		!(user as IUser).roles.some((rol) => rol.name === "CLIENT") &&
		getAge((user as IUser)?.metadata?.birthdate || "2020-12-12") < 18
	) {
		return (
			<div>
				<h2 className="mb-3 fs-20 fw-bold">¡Hola!</h2>
				<p className="fs-18 fw-medium">
					Para empezar a hacer uso de tu membresía, debes pasar a recepción para llenar el formato de
					consentimiento de tu padre o tutor, ya que eres menor de edad.
				</p>
			</div>
		);
	}
	return (
		<ClassesContext.Provider value={values}>
			<Outlet />
		</ClassesContext.Provider>
	);
};
