import { SetStateAction } from "react";
import "./AvailableClassCard.scss";
import { useUserContext } from "@src/context/UserContext";
import { add, isWithinInterval, parseISO } from "date-fns";
import { lockedClasses } from "@src/utils/LockedClasses";

interface AvailableClassCardPropsI {
	class: any;
	setScheduleClassModal?: React.Dispatch<SetStateAction<any>>;
	setScheduleWaitingListModal?: React.Dispatch<SetStateAction<any>>;
	setLeaveWaitingListModal?: React.Dispatch<SetStateAction<any>>;
}

function AvailableClassCard(props: AvailableClassCardPropsI) {
	const { user } = useUserContext();
	const available =
		props.class.locked && lockedClasses.includes(props.class.name) ? 0 : parseInt(props.class.available);

	const now = new Date();
	const thirtyMinutesFromNow = add(now, { minutes: 30 });

	const isClosed = isWithinInterval(parseISO(props.class.startDate), {
		start: now,
		end: thirtyMinutesFromNow,
	});

	const { setScheduleClassModal, setScheduleWaitingListModal, setLeaveWaitingListModal } = props;

	return (
		<div className="available-class-card-main-container">
			<div className="info-container">
				<h3>
					{props.class?.custom_name || props.class.name}{" "}
					<span className={available > 0 ? "available" : "full"}>
						Espacios: {available > 0 ? available : 0}
					</span>
				</h3>
				<h4>
					<b>Coach:</b> {props.class.fullname}
				</h4>
			</div>
			<div className="actions-container">
				<h4>
					{props.class.startHour} - {props.class.endHour}
				</h4>
				{available > 0 && setScheduleClassModal && (
					<button
						className="btn wellness"
						onClick={() => setScheduleClassModal({ show: true, clase: props.class })}
					>
						Reservar
					</button>
				)}
				{!isClosed &&
					available <= 0 &&
					props.class.waiting_list &&
					props.class.waiting_list.users.findIndex((e: any) => e.id === user.id) === -1 &&
					setScheduleWaitingListModal && (
						<button
							className="btn waiting-list"
							onClick={() => {
								setScheduleWaitingListModal({ show: true, clase: props.class });
							}}
						>
							Lista de espera
						</button>
					)}
				{available <= 0 &&
					props.class.waiting_list &&
					props.class.waiting_list.users.findIndex((e: any) => e.id === user.id) !== -1 &&
					setLeaveWaitingListModal && (
						<button
							className="btn waiting-list"
							onClick={() => {
								setLeaveWaitingListModal({ show: true, clase: props.class });
							}}
						>
							Abandonar
						</button>
					)}
			</div>
		</div>
	);
}

export default AvailableClassCard;
