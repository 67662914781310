import { ReactNode } from "react";
import "./BaseModal.scss";
import { Modal } from "react-bootstrap";

interface BaseModalPropsI {
	children: ReactNode;
	title: string;
	show: boolean;
	onHide: () => void;
	size?: "lg" | "xl" | "sm";
}

function BaseModal({ children, title, show, size = "lg", onHide }: BaseModalPropsI) {
	return (
		<Modal show={show} onHide={onHide} size={size} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter" className="fs-2">
					{title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
		</Modal>
	);
}

export default BaseModal;
