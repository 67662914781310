import { differenceInYears, format, parseISO } from "date-fns";
import { es } from "date-fns/locale";

export function formatStringDate(dateString: string) {
	const date = new Date(dateString); // or any other date
	const formattedDate = format(date, "dd/MM/yyyy");
	return formattedDate;
}

export function fullStringDate(dateString: string) {
	// Split "2025-01-31" into [2025, 1, 31]
	const [year, month, day] = dateString.split("-").map(Number);

	// Create a JS Date in the *local* time zone (naive approach)
	// This ensures day=31 in local time as well
	const naiveDate = new Date(year, month - 1, day);

	// Now format it (example: "31 - enero - 2025")
	return format(naiveDate, "d - MMMM - yyyy", { locale: es });
}

export function ISOtoStandarDate(dateString: string) {
	try {
		const date = parseISO(dateString);

		// Format the Date object into 'dd/mm/yyyy' format
		const formattedDate = format(date, "dd/MM/yyyy");
		return formattedDate;
	} catch (error) {
		console.error(error);
		return "";
	}
}

export function formatCancelled(date: string) {
	try {
		const isoDate = new Date(date);
		const formattedDate = format(
			new Date(isoDate.getTime() + isoDate.getTimezoneOffset() * 60000),
			"EEEE, dd/MM/yyyy hh:mm:ss a",
			{
				locale: es,
			}
		);

		return formattedDate;
	} catch (error) {
		console.error(error);
		return "";
	}
}

export function getAge(birthdate: string) {
	const today = new Date();
	const birthDate = new Date(birthdate);
	const age = differenceInYears(today, birthDate);
	return age;
}
