import {
	Dispatch,
	ReactNode,
	SetStateAction,
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { UserContext, useUserContext } from "./UserContext";
import { Outlet } from "react-router-dom";
import { addClass, getNextClasses } from "@src/services/ClassesService";
import { IUser } from "@src/Interfaces/User";
import { getAge } from "@src/utils/Dates";
import { addDays, differenceInDays, format } from "date-fns";
import { LocationLight } from "@src/Interfaces/Locations";
import { fetchLocationsLight } from "@src/services/Locations";
import { notifyError } from "@src/utils/NotificationFunctions";
import SpinnerCustom from "@src/components/SpinnerCustom/SpinerCustom";
import { useCallback } from "react";

interface ClassesContext {
	clases: any[];
	isFetchingClasses: boolean;
	currentLocation: LocationLight | null;
	locations: LocationLight[];
	currentDate: Date;
	getClasses: () => void;
	nextDay: () => void;
	prevDay: () => void;
	handleNewLocation: (location: LocationLight) => void;
}

export const ClassesContext = createContext<ClassesContext>({
	clases: [],
	isFetchingClasses: true,
	currentLocation: null,
	locations: [],
	currentDate: new Date(),
	getClasses: () => {},
	nextDay: () => {},
	prevDay: () => {},
	handleNewLocation: () => {},
});

export function useClassesContext() {
	return useContext(ClassesContext);
}

const ONE_DAY = 1;
const MAX_DAYS_AHEAD = 30;

export const ClassesContextProvider = () => {
	const [currentDate, setCurrentDate] = useState<Date>(new Date());
	const [clases, setClases] = useState<any[]>([]);
	const [locations, setLocations] = useState<LocationLight[]>([]);
	const [currentLocation, setCurrentLocation] = useState<LocationLight | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [isFetchingClasses, setIsFetchingClasses] = useState(true);
	const { user } = useUserContext();

	const getClasses = useCallback(async () => {
		if (currentLocation) {
			setIsFetchingClasses(true);
			const isoDate = format(currentDate, "yyyy-MM-dd");

			try {
				const res = await getNextClasses(user.id, isoDate, currentLocation.id);
				setClases(res);
				setIsFetchingClasses(false);
			} catch (error) {
				console.error(error);
				notifyError("No pudimos cargar las clases.\nPor favor verifica tu conexión.");
			}
		}
	}, [currentLocation, currentDate]);

	const getLocations = useCallback(async () => {
		try {
			const data = await fetchLocationsLight();
			setLocations(data);
			setLoading(false);
			if (data.length > 0) setCurrentLocation(data[0]);
		} catch (error) {
			console.error(error);
			notifyError("Ha habido un error, por favor revisa tu conexión a internet.");
		}
	}, []);

	const nextDay = () => {
		const newDate = addDays(currentDate, ONE_DAY);
		const daysAhead = differenceInDays(newDate, new Date());

		if (daysAhead > MAX_DAYS_AHEAD) {
			// No puedes ver más de 30 días en el futuro
			return;
		}

		setCurrentDate(newDate);
	};

	const prevDay = () => {
		const newDate = addDays(currentDate, -ONE_DAY);
		const daysDiff = differenceInDays(newDate, new Date());
		if (daysDiff < 0) {
			// no puedes ver las clases del pasado
			return;
		}

		setCurrentDate(newDate);
	};

	const handleNewLocation = async (newLocation: LocationLight) => {
		setCurrentLocation(newLocation);
	};

	useEffect(() => {
		// getClasses();
		getLocations();
	}, [getLocations]);

	useEffect(() => {
		getClasses();
	}, [getClasses]);

	if (
		!(user as IUser).roles.some((rol) => rol.name === "CLIENT") &&
		getAge((user as IUser)?.metadata?.birthdate || "2020-12-12") < 18
	) {
		return (
			<div>
				<h2 className="mb-3 fs-20 fw-bold">¡Hola!</h2>
				<p className="fs-18 fw-medium">
					Para empezar a hacer uso de tu membresía, debes pasar a recepción para llenar el formato de
					consentimiento de tu padre o tutor, ya que eres menor de edad.
				</p>
			</div>
		);
	}

	if (loading || !currentLocation) {
		return <SpinnerCustom />;
	}

	return (
		<ClassesContext.Provider
			value={{
				clases,
				isFetchingClasses,
				currentLocation,
				locations,
				currentDate,
				getClasses,
				nextDay,
				prevDay,
				handleNewLocation,
			}}
		>
			<Outlet />
		</ClassesContext.Provider>
	);
};
